import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "非デザイナーのための「ブランド再入門」",
  "date": "2018-03-09T09:33:08.000Z",
  "slug": "entry/2018/03/09/183308",
  "tags": ["medley"],
  "hero": "./2018_03_09.png",
  "heroAlt": "brand"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部の医療メディアチームでデザインをしている波切です。`}</p>
    <p>{`メドレー開発本部で行われている勉強会「TechLunch」で、デザイナー以外の方も知っておいて損はない「ブランドとは？」というお話をさせていただきました。多くの方が何となく知っていることも多いかもしれませんが、再入門的に参考にしていただけると嬉しいです。`}</p>
    <h1>{`背景`}</h1>
    <p>{`メドレーでは時期を問わずプロダクトの在り方について常日頃から様々な場面で議論がなされています。`}</p>
    <p>{`こういった議論の中でブランドとしてどのように見せられるのが良いだろうか、といった検討をすることも多々あり、改めてブランドについて学び直したいと思ったのが今回のきっかけになります。`}</p>
    <p>{`TechLunch ではエンジニア・デザイナー・ディレクター・医師（！）と様々な職種の人が参加しています。`}</p>
    <p>{`この時代では当たり前かもしれないブランドの基礎を改めて学び直し、デザイナー以外の職種の方にも共有しましたので、ブログでもご紹介させていただければと思います。`}</p>
    <h1>{`ブランドってなんでしょう`}</h1>
    <h2>{`ブランドとブランディングの違いって？￼`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180309/20180309175130.png",
        "alt": "f:id:medley_inc:20180309175130p:plain",
        "title": "f:id:medley_inc:20180309175130p:plain"
      }}></img></p>
    <p>{`まずは混合しやすいブランドとブランディングの定義から。`}</p>
    <blockquote>
      <p parentName="blockquote">{`ある特定の商品やサービスが消費者・顧客によって識別されているとき、`}</p>
      <p parentName="blockquote">{`その商品やサービスを「ブランド」と呼ぶ`}</p>
      <p parentName="blockquote">{`※製品名、パッケージング、広告、価格、使用経験などにより、その製品につけられた製品特性と価値（機能的および非機能的）とのユニークなコンビネーション。消費者・顧客の目から見た場合、その製品を競合から差別化するもの。`}</p>
      <p parentName="blockquote">{`(via `}<a parentName="p" {...{
          "href": "https://www.brand-mgr.org/knowledge/word/"
        }}>{`ブランド・マネージャー認定協会 用語集`}</a>{`)`}</p>
      <p parentName="blockquote">{`ブランディングとは、ブランドに対する共感や信頼などを通じて顧客にとっての価値を高めていく、企業と組織のマーケティング戦略の 1 つ。ブランドとして認知されていないものをブランドに育て上げる、あるいはブランド構成要素を強化し、活性・維持管理していくこと。また、その手法。ここでいうブランドとは高級消費財に限らず、その対象としては、商品やサービス、それらを供給する企業や団体のほか、人物・建築物・史跡・地域 ・祭事など、あらゆるものが該当する。`}</p>
      <p parentName="blockquote">{`(via `}<a parentName="p" {...{
          "href": "https://ja.wikipedia.org/wiki/%E3%83%96%E3%83%A9%E3%83%B3%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0"
        }}>{`wikipedia:ブランディング`}</a>{`)`}</p>
    </blockquote>
    <p><strong parentName="p">{`ブランド`}</strong>{`とは「企業・製品に対して消費者が持っているイメージ(記号)であり、競合との差別化を生み出す価値の総体」を指し、`}</p>
    <p><strong parentName="p">{`ブランディング`}</strong>{`は「ブランド価値を与えるための手段」と捉えることができると思います。`}</p>
    <p>{`この定義になぞらえてコカ・コーラを例にブランドとして認知される具体的なステップとして紹介すると、以下のようになります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180309/20180309175152.png",
        "alt": "f:id:medley_inc:20180309175152p:plain",
        "title": "f:id:medley_inc:20180309175152p:plain"
      }}></img></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`ロゴなどの識別記号が記憶される`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`コカ･コーラのロゴが記憶される (生活者の頭の中に、ロゴなどの識別記号が記憶される)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`記号から体験が思い浮かぶ`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`コカ･コーラのロゴを見れば炭酸飲料であることや、爽やかな気分になれることが思い浮ぶ (識別記号を見れば、知覚体験を想起できる)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`体験から記号を思い出す`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`爽やかな気分になりたいと思ったら、コカ･コーラを思い出す。ロゴが思い浮かぶ (知覚価値が頭に浮かんだら、識別記号が想起される)`}{` `}</p>
      </li>
    </ul>
    <p>{`「ブランド連想」「ブランド資産」といった言葉もあり、ブランド（識別記号と知覚体験）に対してポジティブな印象を築き蓄積させることがブランディングのカギになってきます。`}</p>
    <h1>{`ブランド構築`}</h1>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180309/20180309175222.png",
        "alt": "f:id:medley_inc:20180309175222p:plain",
        "title": "f:id:medley_inc:20180309175222p:plain"
      }}></img></p>
    <p>{`前述の記号をブランドの土台とするとコーポレートアイデンティティ（CI）やヴィジュアルアイデンティティ（VI）といったクリエイティブが大きな役割を果たしますが、現在ではユーザーはブランドを知覚する機会が多く、差別化のためにもブランドは顧客体験をベースに作っていくことも重要になります。`}</p>
    <p>{`｢良い顧客体験を提供しブランドの競争力を高める｣ という考え方に基づいて、ブランド戦略を考える順番は理想的な体験を描いてから必要なモノや技術を考えます。これはブランドに限らず様々な場面で意識したいことです。`}</p>
    <h2>{`ブランドに大事な一貫性`}</h2>
    <p>{`魅力的なブランドは顧客体験が蓄積して形成されていきます。蓄積されるブランド体験の要素は「体験の魅力度」「体験の量と時間」「体験の一貫性」と 3 つあり、ブランドの価値はこの 3 つの要素のかけ算にあります。`}</p>
    <p>{`体験が魅力的であり、その体験の回数が多く長い時間にわたって経験し、体験自体に一貫性があるブランドがユーザーとって良いものとされます。`}</p>
    <p>{`3 つのうち特に重要なものは最後の ｢体験の一貫性｣ にあり、この一貫性は 2 つに分けることができます。`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`時系列で一貫性`}</strong>{`：時代で左右されない顧客体験がブランドから提供される`}</li>
      <li parentName="ul"><strong parentName="li">{`接点の一貫性`}</strong>{`：ブランドを買ったり利用する前から、購入プロセス、購入後の利用シーンにおいて、一貫した顧客体験ができる`}</li>
    </ul>
    <p>{`「モノとコト」にも例えやすいところですが、製品としての一貫性に加えて体験する前後のグランドデザインにも一貫性を持たすことが重要になります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180309/20180309175407.png",
        "alt": "f:id:medley_inc:20180309175407p:plain",
        "title": "f:id:medley_inc:20180309175407p:plain"
      }}></img></p>
    <p>{`例としてスターバックスは広告費をほとんどかけずに今のブランド認知度を築いたことは有名ですが、そこにはブランドの価値を築くためのブランディングに一貫性があり、かつそれらが時代性とマッチしたのが要因ではないかと思います。`}</p>
    <h2>{`ブランディングのメリット`}</h2>
    <ul>
      <li parentName="ul">{`選択意思決定の単純化・固定化`}</li>
      <li parentName="ul">{`顧客の知識が整理されることで競合と差別化され再び同じ物を選ぶようになる`}</li>
      <li parentName="ul">{`ユーザーのロイヤル化`}</li>
      <li parentName="ul">{`親しみや信頼が増大されることでブランドロイヤリティが形成される`}</li>
    </ul>
    <p>{`なぜブランドにこだわるのか、という点でもこれらメリットを実現していくことが重要です。`}</p>
    <p>{`デザイナーとして気遣い溢れる UI や、サービスの思想を記号に落とし込んだ VI などモノづくり視点でのこだわりや重要さを踏まえた上で、他職種の方にもこれらのメリットや競争としての必要であることを理解してもらえるよう働きかけることを意識していきたいところです。`}</p>
    <h2>{`ブランディングの悩みどころ`}</h2>
    <p>{`ここからは基礎を踏まえて、実践的に行われているブランディングで悩みやすいところを事例で紹介しながら簡単なディスカッションをしていきました。一部だけ抜粋してご紹介します。`}</p>
    <h2>{`製品ブランドの管理`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180309/20180309175511.png",
        "alt": "f:id:medley_inc:20180309175511p:plain",
        "title": "f:id:medley_inc:20180309175511p:plain"
      }}></img></p>
    <p>{`会社のブランド管理の思想を定義できても、変化の早い業界であればイメージ通りになかなか通らないことも多々有りえます。`}</p>
    <p>{`少ないブランド資産を活用する意味でもスタートアップは横串型に整理されることが多くありますが、ある程度の規模感になって基礎体力がある企業であると個別適応型でもブランドとして信頼性を保てますし、横串型に比べて動きやすさがあると思います。`}</p>
    <p>{`一方で、プラットフォーマーとして存在したいなら横串のブランド価値からファンを囲い込むべきかもしれません。Techlunch の中でもメドレーであればどうあると良いかについて議論ができました。`}</p>
    <h2>{`ブランディングのタイミング`}</h2>
    <p>{`顧客体験の蓄積が重要なブランドでありますが、どの段階でブランディングに取り組むかは状況によりますが意見が出やすいところだと思います。`}</p>
    <p>{`ICC FUKUOKA2017 にてブランディングについてのセッションがあり、取り組むタイミングについての議論がうまくまとめられていたため Techlunch で事例として共有しました。プロダクトとしての差別化が基本であることに加えて、ブランドの在り方を組織にインストールさせるためにブランディングに取り組むケースも紹介されています。`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`僕はブランドが常に必要かと言うと“No”だと思います。`}</strong></p>
      <p parentName="blockquote">{`コモディティ化し始めたり、競争が始まって差別化しなくてはならない時に、プロダクトの機能などで差別化ができない、または必ずしもパフォーマンスで差別化ができない時に唯一残された選択が、ブランドを作るということであると思います。`}</p>
      <p parentName="blockquote">{`[株式会社 Bloom&Co. 彌野泰弘]`}</p>
      <p parentName="blockquote"><strong parentName="p">{`ユーザーを獲りにいくというフェーズですよね。`}</strong></p>
      <p parentName="blockquote">{`メルカリの場合、やはり最初の 100 万ダウンロードくらいまでは、結構チューニングをしながらオンラインのマーケティングで（ユーザーを）獲得してきてきました。`}</p>
      <p parentName="blockquote">{`~中略~`}</p>
      <p parentName="blockquote">{`やはり 100 万ダウンロードくらいあって、リテンションレートが高くユーザーが積み上がる状態になっていれば、大きくマスマーケティング（TVCM）をやっても獲得したユーザーは残りますからね。`}</p>
      <p parentName="blockquote">{`[株式会社メルカリ 小泉文明]`}</p>
      <p parentName="blockquote"><strong parentName="p">{`僕は、ユーザーを獲得する手前でブランドが必要だと思っているんですよね。`}</strong></p>
      <p parentName="blockquote">{`ブランドというのは必ずしも外に対してだけではなくて、（組織の）中の人に対して必要であることもすごく多いのです。`}</p>
      <p parentName="blockquote">{`~中略~`}</p>
      <p parentName="blockquote">{`資生堂は当時 130 年くらい経っている会社だったので、その 130 年もこの後の 130 年も、世の中を美しくするとか、人を美しくするとか、それは女性に限らず、お化粧に限らず美しくするということが必要だよね、軸だよねという話になって、そのスローガンと共に前田新社長体制で進んでいくことになりました。`}</p>
      <p parentName="blockquote">{`[株式会社 dof 齋藤太郎]`}</p>
      <p parentName="blockquote">{`(via `}<a parentName="p" {...{
          "href": "https://industry-co-creation.com/management/19837"
        }}>{`スタートアップのブランディングはいつから必要か？【F17-7C #3】`}</a>{`)`}</p>
    </blockquote>
    <p>{`共有した後には、リリース当初からブランドやクリエイティブが作り込まれたプロダクトが最近話題になることが多いということも話に上がりました。`}</p>
    <p>{`もちろんブランディングのタイミングは状況により千差万別ですが、プロダクトをゼロから作る際には、プロダクトの在り方と合わせてどんなブランドを作って行きたいのかも早めに考えられることが必要だ、など具体的なディスカッションも出来ました。`}</p>
    <h2>{`新たなブランドが受け入れられないことも`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180309/20180309175734.png",
        "alt": "f:id:medley_inc:20180309175734p:plain",
        "title": "f:id:medley_inc:20180309175734p:plain"
      }}></img></p>
    <p>{`基本的にブランドを新しくすることは見慣れたものが変わってしまう恐怖から反発を生むケースが多いですが、その中でも GAP はロゴの 2010 年リニューアル公表後ネット上からの反発が強くわずか 6 日間という短期間で新しいロゴの使用を止め、元のロゴに戻してしまうという一件がありました。（この件は`}<a parentName="p" {...{
        "href": "https://techcrunch.com/2010/10/11/gap-logo-redesign/"
      }}>{`株価`}</a>{`にも大きな影響を与えました）`}</p>
    <p>{`一方、同じような境遇で Airbnb も 2014 年のリニューアル当初ロゴは不評でしたが今ではリニューアルの成功例として扱われる存在になっています。`}</p>
    <p>{`Techlunch でも歴史の有無が差になったのか、など様々な意見が出てきました。この件はロゴとしての王道を極めようとした以上の背景を提示出来なかった Gap に対して、`}<a parentName="p" {...{
        "href": "https://www.underconsideration.com/brandnew/archives/new_logo_and_identity_for_airbnb_by_designstudio.php"
      }}>{`Airbnb はサービスのストーリーをロゴに内包`}</a>{`し、地道にロゴを介したコミュニケーションに徹したことが明暗を分けたように思います。`}</p>
    <p>{`この事例はロゴのアイデンティティをどこまで確立させられたかの違いにあることに加えて、デザインの意思決定を一時的な多数意見に委ねた事例としても学ぶことが多いです。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`私個人が元々グラフィックデザインをしていた経験もあり、今まではブランディングの中でも興味が CI や VI に傾倒していましたが、体験全体がブランドに大きな影響を与えるこの時代、組織としてブランド構築をしていく意識が重要であることが今回の大きな学びでした。`}</p>
    <p>{`また基礎を学ぶ上で書籍を読んでいましたが、最近の事例などを学ぶ際にはブログなどでの情報収集がとても有効で、学び方も変わってきているなと思いました。今回紹介した内容はあくまで入門編なので、興味があれば今回参考にさせていただいた本とブログ・記事からより深堀りが出来るのでオススメします。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.co.jp/%E3%83%97%E3%83%A9%E3%83%83%E3%83%88%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0-%E3%83%96%E3%83%A9%E3%83%B3%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0-%E5%B7%9D%E4%B8%8A-%E6%85%8E%E5%B8%82%E9%83%8E/dp/4797373113"
        }}>{`プラットフォームブランディング`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.co.jp/%E3%82%B3%E3%83%BC%E3%83%9D%E3%83%AC%E3%83%BC%E3%83%88%E3%83%BB%E3%82%A2%E3%82%A4%E3%83%87%E3%83%B3%E3%83%86%E3%82%A3%E3%83%86%E3%82%A3%E6%88%A6%E7%95%A5%E2%80%95%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%8C%E4%BC%81%E6%A5%AD%E7%B5%8C%E5%96%B6%E3%82%92%E5%A4%89%E3%81%88%E3%82%8B-%E4%B8%AD%E8%A5%BF-%E5%85%83%E7%94%B7/dp/4416610130"
        }}>{`コーポレート・アイデンティティ戦略―デザインが企業経営を変える`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jacs.gr.jp/announcement/20120305_AOKI.pdf"
        }}>{`ブランド・エクイティ研究の展望 ～価値をめぐる議論の系譜を中心に～`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.slideshare.net/takehisagokaichi/ux-46532066"
        }}>{`UX とブランド`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.missiondrivenbrand.jp/entry/brandidentity"
        }}>{`ブランドアイデンティティとは｜ブランド構築を成果に導く BI の創り方｜成功事例有`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://industry-co-creation.com/management/19837"
        }}>{`スタートアップのブランディングはいつから必要か？`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.btrax.com/jp/2017/07/12/gapandairbnb/"
        }}>{`ロゴのリデザインーなぜ Gap が失敗し Airbnb が受け入れられたのか`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://forbesjapan.com/articles/detail/19596"
        }}>{`武器になる「ロゴ」を生み出す、CI デザインとは何か？`}</a></li>
    </ul>
    <p>{`Techlunch ではデザイナー以外の方からも事例に対して意見が出たり、疑問に対して参加者でディスカッションが出来たことがとても有意義でした。今後はこれらを意識しながらブランドの土台となる価値や在り方をデザイン出来るようにしていきたいと思います。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{` `}{`メドレーでは、医療介護の求人サイト「ジョブメドレー」、医師たちがつくるオンライン医療事典「MEDLEY」、口コミで探せる介護施設の検索サイト「介護のほんね」、オンライン診療アプリ「CLINICS」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`興味のある方、ぜひメドレーへ遊びにいらしてください。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>
    <cite className="hatena-citation"></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      